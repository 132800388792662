import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { ResellerCreateButton } from "reseller/button/ResellerCreateButton";
import { ResellerListContainer } from "reseller/list/ResellerListContainer";

export function ResellerListPage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Resellers</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <ResellerCreateButton />
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResellerListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
