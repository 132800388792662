import { FolderListItem } from "model/FolderListItem";
import { FolderType } from "model/FolderType";
import { useLocationContext } from "hooks/useLocationContext";
import { Link, useSearchParams } from "react-router-dom";
import React from "react";
import { FaFolder } from "react-icons/fa";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { FolderEditButton } from "core/folder/FolderEditButton/FolderEditButton";
import { FolderMoveButtonContainer } from "core/folder/FolderMoveButton/FolderMoveButtonContainer";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";
import { FolderDeleteButton } from "core/folder/FolderDeleteButton/FolderDeleteButton";
import { ButtonGroup, Col } from "react-bootstrap";
import styles from "./FolderRowItem.scss";
import { RowListItem } from "core/components/RowList/RowListItem";

interface FolderTableListItemProps {
    folderListItem: FolderListItem;
    changeEvent: string;
    folderType: FolderType;
}

export function FolderRowItem(
    props: FolderTableListItemProps
) {
    const locationContext = useLocationContext();
    const [_searchParams, setSearchParams] = useSearchParams();

    return <RowListItem>
        <Col md={10} className={styles.main}>
            <FaFolder />
            <Link
                className="nav nav-link"
                to="#"
                onClick={(e) => {
                    e.preventDefault();
                    setSearchParams({
                        folderId: props.folderListItem.id,
                    });
                }}
            >
                {props.folderListItem.name
                    ? props.folderListItem.name
                    : props.folderListItem.id}
            </Link>
        </Col>
        <Col md={2} className="text-end">
            <ButtonGroup>
                <HasLocationRole
                    locationId={locationContext}
                    role={ScopedRole.EDITOR}
                >
                    <FolderEditButton
                        folderId={props.folderListItem.id}
                        successEvent={props.changeEvent}
                    />
                    <FolderMoveButtonContainer
                        folderId={props.folderListItem.id}
                        successEvent={props.changeEvent}
                        folderType={props.folderType}
                    />

                    <OverflowMenu variant="secondary">
                        <FolderDeleteButton
                            folderId={props.folderListItem.id}
                            successEvent={props.changeEvent}
                            variant="dropdown"
                        />
                    </OverflowMenu>
                </HasLocationRole>
            </ButtonGroup>
        </Col>
    </RowListItem>;
}
