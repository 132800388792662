import React, { ReactElement, useEffect } from "react";
import { useFetchAllSchedules } from "hooks/api/schedule/useFetchAllSchedules";
import { Schedule } from "model/Schedule";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";

interface DeviceScheduleControlProps {
    contentId: string;
    aspectRatioId: string;
}

export function DeviceScheduleControl(
    props: DeviceScheduleControlProps,
): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchAllSchedules(
        props.aspectRatioId,
    );
    const { register, setValue } = useFormContext();

    useEffect((): void => {
        setValue("contentId", props.contentId);
    }, [response]);

    const scheduleList = (response || []).map(
        (schedule: Schedule, index: number): ReactElement => {
            return (
                <option key={index} value={schedule.id}>
                    {schedule.name} ({schedule.id})
                </option>
            );
        },
    );

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <Form.Label className="mt-2">Schedule</Form.Label>
            <Form.Select {...register("contentId")}>
                <option value="">-</option>
                {scheduleList}
            </Form.Select>
        </ResponseErrorHandler>
    );
}
