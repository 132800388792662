import * as React from "react";
import { ReactElement } from "react";
import styles from "scene/ScenesOverviewPage/components/SceneCreator/TemplatePreview.scss";
import { FaCheck } from "react-icons/fa";
import { Card, Col } from "react-bootstrap";
import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";
import { classNames, TemplateLayout } from "@castia/sdk";
import { useOrientation } from "hooks/useOrientation";

interface LayoutPreviewProps {
    index: number;
    selected: boolean;
    template: EnrichedTemplateConfiguration;
    layout: TemplateLayout;
    selectedCallback: (index: number, layoutId: string) => void;
    aspectRatio: string;
}

function LayoutPreview(props: LayoutPreviewProps): ReactElement {
    const aspectRatioThumbnail = props.layout.supportedAspectRatios.find(
        (aspectRatio) => {
            return aspectRatio.aspectRatio === props.aspectRatio;
        },
    );
    const thumbnail = aspectRatioThumbnail.thumbnail
        ? `${props.template.resourceDirectory}/${aspectRatioThumbnail.thumbnail}`
        : null;
    const orientation = useOrientation(props.aspectRatio);

    return (
        <Col
            md={orientation === "portrait" ? 3 : 4}
            key={props.index}
            className={classNames(styles.previewBlock, "mb-4")}
        >
            <div className={props.selected ? styles.selected : ""}>
                <Card>
                    <Card.Img variant="top" src={thumbnail} />
                    <Card.Body>
                        <Card.Text>{props.layout.name}</Card.Text>
                    </Card.Body>
                    <div
                        className={styles.selectedOverlay}
                        onClick={(): void => {
                            props.selectedCallback(
                                props.index,
                                props.layout.id,
                            );
                        }}
                    >
                        <FaCheck className={styles.check} />
                    </div>
                </Card>
            </div>
        </Col>
    );
}

export default LayoutPreview;
