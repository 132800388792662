import React, { ReactElement } from "react";
import { Schedule } from "model/Schedule";
import { useUpdateSchedule } from "hooks/api/schedule/useUpdateSchedule";
import { FormProvider, useForm } from "react-hook-form";
import {
    FormValues,
    ScheduleForm,
} from "schedule/schedule/components/ScheduleForm";
import Modal from "core/components/UI/Modal/Modal";

interface ScheduleUpdateModalProps {
    schedule: Schedule;
    onDone: () => void;
    show: boolean;
}

export function ScheduleUpdateModal(
    props: ScheduleUpdateModalProps,
): ReactElement {
    const { sendRequest, error, reset } = useUpdateSchedule(props.schedule.id);

    async function onSubmit(data: FormValues): Promise<void> {
        const result = await sendRequest({
            name: data.name,
            fallbackChannel: data.fallbackChannel ? data.fallbackChannel : null,
        });
        if (result) {
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            title="Update schedule"
        >
            <ScheduleForm
                error={error}
                onSubmit={onSubmit}
                handleClose={handleClose}
                existingSchedule={props.schedule}
            />
        </Modal>
    );
}
