import * as React from "react";
import { ReactElement } from "react";

import { ButtonGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteSceneButton from "scene/ScenesEditorPage/components/DeleteSceneButton/DeleteSceneButton";
import { absoluteScenesPath } from "core/util/routes";
import { FolderListItem } from "model/FolderListItem";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { SceneMoveButton } from "scene/ScenesOverviewPage/components/SceneMoveButton";
import { useLocationContext } from "hooks/useLocationContext";
import { SceneCopyDropdownItem } from "scene/ScenesOverviewPage/components/SceneCopyDropdownItem";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { AspectRatioIcon } from "core/field/AspectRatioIcon";
import { useAspectRatioLabel } from "hooks/useAspectRatioLabel";
import { FolderRowItem } from "core/folder/FolderRowItem/FolderRowItem";
import { FolderNavigateUpRow } from "core/folder/FolderTableNavigateUp/FolderNavigateUpRow";
import { RowListItem } from "core/components/RowList/RowListItem";
import { RowListGroup } from "core/components/RowList/RowListGroup";

interface ScenesListProps {
    sceneListItems: FolderListItem[];
    folderId?: string;
}

export function ScenesList(props: ScenesListProps): ReactElement {
    const locationContext = useLocationContext();
    const getAspectRatioLabel = useAspectRatioLabel();

    function scenesList() {
        return props.sceneListItems?.map(
            (folderListItem: FolderListItem, index: number): ReactElement => {
                if (folderListItem.type === "FOLDER") {
                    return (
                        <FolderRowItem
                            key={index}
                            folderListItem={folderListItem}
                            changeEvent={LocalEvents.SCENES_CHANGED}
                            folderType={FolderType.SCENE}
                        />
                    );
                }
                return (
                    <RowListItem key={index}>
                        <Col md={7}>
                            <Link
                                className="nav nav-link"
                                to={`${absoluteScenesPath}/${folderListItem.id}`}
                                data-cy="scene-list-item"
                            >
                                {folderListItem.name
                                    ? folderListItem.name
                                    : folderListItem.id}
                            </Link>
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                            <AspectRatioIcon
                                aspectRatio={
                                    folderListItem.aspectRatio.aspectRatio
                                }
                                size={20}
                            />
                            &nbsp;
                            {getAspectRatioLabel(
                                folderListItem.aspectRatio.aspectRatio,
                            )}
                        </Col>
                        <Col md={2} className="text-end">
                            <ButtonGroup>
                                <HasLocationRole
                                    locationId={locationContext}
                                    role={ScopedRole.EDITOR}
                                >
                                    <SceneMoveButton
                                        sceneId={folderListItem.id}
                                        currentFolderId={folderListItem.folder}
                                    />
                                    <OverflowMenu variant="secondary">
                                        <SceneCopyDropdownItem
                                            sceneId={folderListItem.id}
                                        />
                                        <DeleteSceneButton
                                            sceneId={folderListItem.id}
                                            variant="dropdown"
                                        />
                                    </OverflowMenu>
                                </HasLocationRole>
                            </ButtonGroup>
                        </Col>
                    </RowListItem>
                );
            },
        );
    }

    return (
        <>
            <RowListGroup dataCy="scenes-list">
                <FolderNavigateUpRow parentFolderId={props.folderId} />
                {scenesList()}
            </RowListGroup>
        </>
    );
}
