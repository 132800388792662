import { LocationAspectRatio } from "model/LocationAspectRatio";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { sleep } from "core/util/sleep";
import styles from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene.scss";
import ReactResizeDetector from "react-resize-detector";
import * as React from "react";

interface ScaledSceneProps {
    locationAspectRatio: LocationAspectRatio;
}

export function ScaledScene(props: PropsWithChildren<ScaledSceneProps>) {
    const sceneWrapper = useRef<HTMLDivElement>();
    const [scale, setScale] = useState(1);
    const [showScene, setShowScene] = useState(false);

    useEffect(() => {
        updateSceneDimensions();
        window.addEventListener("resize", updateSceneDimensions.bind(this));
        return () => {
            window.removeEventListener(
                "resize",
                updateSceneDimensions.bind(this),
            );
        };
    }, []);

    async function updateSceneDimensions() {
        /* TODO Remove this sleep. Probably not needed in production mode. This is here to ensure the width which is retrieved
            is correct. It seems that the Sass is evaluated client side and classes were being added after this code ran... */
        await sleep(100);
        if (!sceneWrapper.current) {
            return;
        }

        const [w, _] = props.locationAspectRatio.aspectRatio
            .split(":")
            .map(Number);
        const width = 120 * w;

        const newScale = sceneWrapper.current.clientWidth / width;

        setScale(newScale);
        setShowScene(true);
    }

    const [w, h] = props.locationAspectRatio.aspectRatio.split(":").map(Number);
    const width = 120 * w;
    const height = 120 * h;

    return (
        <div
            id="scaled-scene"
            className={styles.sceneWrapper}
            ref={sceneWrapper}
            style={{
                paddingTop: `${(height / width) * 100}%`,
            }}
        >
            {showScene ? (
                <div
                    className={styles.scene}
                    style={{
                        transform: `scale(${scale})`,
                        width: `${width}px`,
                        height: `${height}px`,
                    }}
                >
                    {props.children}
                </div>
            ) : (
                ""
            )}
            <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={updateSceneDimensions.bind(this)}
            />
        </div>
    );
}
