import { Form } from "react-bootstrap";
import Modal from "core/components/UI/Modal/Modal";
import { SceneInput } from "scene/input/SceneInput/SceneInput";
import Button from "core/components/UI/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { default as React } from "react";
import useAddScenesToChannel from "hooks/api/channel/useAddScenesToChannel";
import usePlugins from "hooks/usePlugins";
import ChannelScene from "model/ChannelScene";
import Scene from "model/Scene";
import Channel from "model/Channel";

interface AddSceneToChannelModalBodyProps {
    channel: Channel;
    handleClose: () => void;
}

interface FormType {
    scenes: Scene[];
}

export function AddSceneToChannelModalBody(props: AddSceneToChannelModalBodyProps) {
    const { sendRequest } = useAddScenesToChannel(props.channel.id);
    const form = useForm();
    const watchScenes = form.watch("scenes");
    const { findTemplate } = usePlugins();

    async function onSubmit(data: FormType): Promise<void> {
        const channelScenes = data.scenes.map((scene): ChannelScene => {
            const metadata = findTemplate(scene.template);
            if (!metadata.defaultDuration) {
                return new ChannelScene(scene.id, 15, null);
            }
            const defaultDuration =
                metadata.defaultDuration instanceof Function
                    ? metadata.defaultDuration()
                    : metadata.defaultDuration;
            return new ChannelScene(scene.id, defaultDuration, null);
        });

        await sendRequest({
            channelScenes: channelScenes,
        });

        props.handleClose();
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <SceneInput locationAspectRatio={props.channel.aspectRatio} />
                </Modal.Body>
                <Modal.Footer>
                    <span>
                        {watchScenes &&
                            watchScenes.length +
                                ` scene${
                                    watchScenes.length > 1 ||
                                    watchScenes.length === 0
                                        ? "s"
                                        : ""
                                } selected`}
                    </span>
                    <Button variant={"secondary"} onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant={"primary"} type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </FormProvider>
    );
}
