import React, { ReactElement } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ApiError } from "@castia/sdk/dist/hooks/ApiError";
import { Form, FormGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "core/components/UI/Modal/Modal";
import Error from "core/components/UI/Error/Error";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import Button from "core/components/UI/Button/Button";
import useFetchAllChannels from "hooks/api/channel/useFetchAllChannels";
import { ResponseErrorHandler } from "@castia/sdk";
import styles from "device/show/DeviceScreenshotCarouselCard.scss";
import { FaQuestionCircle } from "react-icons/fa";
import { AspectRatioInput } from "core/input/AspectRatioInput";
import { LocationAspectRatio } from "model/LocationAspectRatio";
import { Schedule } from "model/Schedule";

interface ScheduleFormProps {
    error: ApiError;
    onSubmit: (data: FormValues) => Promise<void>;
    handleClose: () => void;
    existingSchedule?: Schedule;
}

export interface FormValues {
    name: string;
    fallbackChannel: string;
    aspectRatio: LocationAspectRatio;
}

export function ScheduleForm(props: ScheduleFormProps): ReactElement {
    const form = useForm<FormValues>({
        defaultValues: props.existingSchedule
            ? {
                  name: props.existingSchedule.name,
                  fallbackChannel: props.existingSchedule.fallbackChannel?.id,
                  aspectRatio: props.existingSchedule.aspectRatio,
              }
            : {},
    });
    const { errors } = form.formState;
    const aspectRatio = useWatch({ name: "aspectRatio", control: form.control });
    const channels = useFetchAllChannels(aspectRatio?.id);

    const renderTooltip = (tooltipProps: any) => (
        <Tooltip {...tooltipProps}>
            The fallback channel is an optional channel that will be shown if
            there is no other content scheduled. If there is neither a fallback
            channel nor any specified content, a placeholder screen will be
            displayed.
        </Tooltip>
    );

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.onSubmit)}>
                <Modal.Body>
                    {props.error && (
                        <Error>
                            Something went wrong while saving the schedule.
                        </Error>
                    )}
                    <FormGroup>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            {...form.register("name", {
                                required: "Name is a required field",
                            })}
                        />
                        {errors.name && (
                            <FormInputError>
                                {errors.name.message as string}
                            </FormInputError>
                        )}
                    </FormGroup>
                    {!props.existingSchedule && (
                        <FormGroup>
                            <AspectRatioInput />
                        </FormGroup>
                    )}
                    <br />
                    <ResponseErrorHandler
                        isLoading={channels.isLoading}
                        error={channels.error}
                        retryAction={() => channels.refreshData()}
                    >
                        <FormGroup>
                            <Form.Label>
                                Fallback Channel
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <span className={styles.disclaimer}>
                                        <FaQuestionCircle />
                                    </span>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Select {...form.register("fallbackChannel")}>
                                <option value="">-</option>
                                {channels.response &&
                                    channels.response.map((channel) => {
                                        return (
                                            <option
                                                key={channel.id}
                                                value={channel.id}
                                            >
                                                {channel.title} ({channel.id})
                                            </option>
                                        );
                                    })}
                            </Form.Select>
                            {errors.fallbackChannel && (
                                <FormInputError>
                                    {errors.fallbackChannel.message as string}
                                </FormInputError>
                            )}
                        </FormGroup>
                    </ResponseErrorHandler>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </FormProvider>
    );
}
