import Device from "model/Device";
import { Form, FormGroup, Modal } from "react-bootstrap";
import React, { useState } from "react";
import Button from "core/components/UI/Button/Button";
import { ContentInput } from "core/components/input/ContentInput";
import { FormProvider, useForm } from "react-hook-form";
import useUpdateDevice from "hooks/api/device/useUpdateDevice";
import styles from "./DeviceContentFormModal.scss";
import { FaPaintBrush } from "react-icons/fa";

interface DeviceContentModalProps {
    device: Device;
    showButton?: boolean;
}

interface DeviceContentForm {
    contentId: string;
    contentType: string;
}

export function DeviceContentFormModal(props: DeviceContentModalProps) {
    const [showModal, setShowModal] = useState(false);
    const { sendRequest } = useUpdateDevice(props.device.id, true);

    const form = useForm<DeviceContentForm>({
        defaultValues: {
            contentId: props.device.contentId,
            contentType: props.device.contentType,
        },
    });

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    async function onSubmit(data: DeviceContentForm): Promise<void> {
        await sendRequest({
            contentId: data.contentId,
            contentType: data.contentType,
        });
        closeModal();
    }

    return (
        <>
            <FormProvider {...form}>
                <Modal
                    show={showModal}
                    onHide={closeModal}
                    size={"lg"}
                    title="Update Event"
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <Form>
                        <Modal.Header>
                            Select content for{" "}
                            {props.device.name || props.device.id}
                        </Modal.Header>
                        <Modal.Body>
                            <FormGroup>
                                <Form.Label className="mt-2">
                                    Content Type
                                </Form.Label>
                                <br />
                                <ContentInput
                                    initialContentType={
                                        props.device.contentType || "SCHEDULE"
                                    }
                                    initialContentId={props.device.contentId}
                                    aspectRatioId={
                                        props.device.locationAspectRatio.id
                                    }
                                />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={"secondary"} onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </FormProvider>
            {props.showButton ? (
                <Button
                    variant="info"
                    onClick={openModal}
                    title="Select content"
                >
                    <FaPaintBrush />
                </Button>
            ) : (
                <span onClick={openModal} className={styles.pencil}>
                    <FaPaintBrush />
                </span>
            )}
        </>
    );
}
