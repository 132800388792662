import React, { ReactElement } from "react";
import { ButtonGroup, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Device from "model/Device";
import DeviceDeleteButton from "device/button/DeviceDeleteButton";
import { absoluteDevicesPath } from "core/util/routes";
import { DeviceConnectedField } from "device/field/DeviceConnectedField";
import styles from "./DeviceList.scss";
import { useLocationContext } from "hooks/useLocationContext";
import { DeviceContentFormModal } from "device/edit/DeviceContentFormModal";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { HasOrganizationRole } from "core/auth/permissions/HasOrganizationRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { useAspectRatioLabel } from "hooks/useAspectRatioLabel";
import { AspectRatioIcon } from "core/field/AspectRatioIcon";

interface DeviceListProps {
    devices: Device[];
}

function DeviceList(props: DeviceListProps) {
    const locationContext = useLocationContext();
    const organizationContext = useOrganizationContext();
    const getAspectRatioLabel = useAspectRatioLabel();

    const devicesList = props.devices.map(
        (device: Device, index: number): ReactElement => {
            return (
                <tr key={index}>
                    <td>
                        <Link
                            className="nav nav-link"
                            to={`${absoluteDevicesPath}/${device.id}`}
                        >
                            {device.name ? device.name : device.id}
                        </Link>
                    </td>
                    <td className={styles.connectedColumn}>
                        <DeviceConnectedField connected={device.connected} />
                    </td>
                    <td className="align-middle">
                        <div className="d-flex align-items-center">
                            <AspectRatioIcon
                                aspectRatio={
                                    device.locationAspectRatio.aspectRatio
                                }
                                size={20}
                            />
                            &nbsp;
                            {getAspectRatioLabel(
                                device.locationAspectRatio.aspectRatio,
                            )}
                        </div>
                    </td>
                    <td className="text-end">
                        <HasLocationRole
                            locationId={locationContext}
                            role={ScopedRole.EDITOR}
                        >
                            <ButtonGroup>
                                <DeviceContentFormModal
                                    device={device}
                                    showButton
                                />
                                <HasOrganizationRole
                                    organizationId={organizationContext}
                                    role={ScopedRole.SCOPE_ADMIN}
                                >
                                    <OverflowMenu variant="secondary">
                                        <DeviceDeleteButton
                                            deviceId={device.id}
                                            variant="dropdown"
                                        />
                                    </OverflowMenu>
                                </HasOrganizationRole>
                            </ButtonGroup>
                        </HasLocationRole>
                    </td>
                </tr>
            );
        },
    );

    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Connected</th>
                    <th>Orientation</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{devicesList}</tbody>
        </Table>
    );
}

export default DeviceList;
