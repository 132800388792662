import React, { ReactElement } from "react";
import { FolderType } from "model/FolderType";
import { RenderedSceneField } from "core/components/fields/RenderedSceneField";
import { SceneFolderListItem } from "model/SceneFolderListItem";
import { Card, Col, Row } from "react-bootstrap";
import { AspectRatioBox } from "core/components/AspectRatioBox/AspectRatioBox";
import { FaCheck, FaFolder } from "react-icons/fa";
import styles from "scene/input/SceneInput/SceneSelector.scss";
import { classNames } from "@castia/sdk";
import { Folder } from "model/Folder";
import { flatten } from "core/auth/flattenTree";
import { findFolderInTree } from "core/util/findFolderInTree";
import { LocationAspectRatio } from "model/LocationAspectRatio";
import { useOrientation } from "hooks/useOrientation";

interface SceneInputProps {
    scenes: SceneFolderListItem[];
    toggleSelect: (scene: SceneFolderListItem) => void;
    selectedScenes: SceneFolderListItem[];
    isSelected: (scene: SceneFolderListItem) => boolean;
    onFolderClick: (folderId: string) => void;
    folderTree: Folder[];
    locationAspectRatio: LocationAspectRatio;
}

export function SceneSelector(props: SceneInputProps): ReactElement {
    const orientation = useOrientation(props.locationAspectRatio.aspectRatio);
    const sceneElements = props.scenes.map((scene, index): ReactElement => {
        if (scene.type === FolderType.SCENE) {
            return (
                <Col
                    lg={3}
                    key={index}
                    className={props.isSelected(scene) ? styles.selected : ""}
                >
                    <Col
                        lg={orientation === "portrait" ? 8 : 12}
                        className="m-auto"
                    >
                        <Card className="my-2">
                            <div
                                className={styles.selectedOverlay}
                                onClick={() => props.toggleSelect(scene)}
                                data-cy="scene-selector-select"
                            >
                                <div
                                    className={classNames(
                                        styles.checkContainer,
                                        "rounded-circle",
                                    )}
                                >
                                    <FaCheck />
                                </div>
                            </div>
                            <Card.Body className="p-0">
                                <AspectRatioBox
                                    locationAspectRatio={
                                        props.locationAspectRatio
                                    }
                                >
                                    <RenderedSceneField scene={scene} />
                                </AspectRatioBox>

                                <div className="p-2 text-center text-truncate">
                                    {scene.name}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Col>
            );
        }

        const foundInTree = findFolderInTree(props.folderTree, scene.id);
        const flatFolderTree = flatten<Folder>([foundInTree], "children").map(
            (folder) => folder.id,
        );
        const selectedInFolder = props.selectedScenes.filter(
            (selectedScene) => {
                return flatFolderTree.includes(selectedScene.folder);
            },
        ).length;

        return (
            <Col
                lg={3}
                key={index}
                className={selectedInFolder > 0 ? styles.selected : ""}
            >
                <Col
                    lg={orientation === "portrait" ? 8 : 12}
                    className="m-auto"
                >
                    <Card className="my-2">
                        <div
                            className={styles.selectedOverlay}
                            onClick={() => props.onFolderClick(scene.id)}
                            data-cy="scene-selector-folder"
                        >
                            <div
                                className={classNames(
                                    styles.checkContainer,
                                    "rounded-circle",
                                )}
                            >
                                <span>{selectedInFolder}</span>
                            </div>
                        </div>
                        <Card.Body className="p-0">
                            <AspectRatioBox
                                locationAspectRatio={props.locationAspectRatio}
                            >
                                <div className={styles.folder}>
                                    <FaFolder />
                                </div>
                            </AspectRatioBox>
                            <div className="p-2 text-center text-truncate">
                                {scene.name}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Col>
        );
    });

    return <Row>{sceneElements}</Row>;
}
