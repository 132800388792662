export function useAspectRatioLabel() {
    return (aspectRatio: string): string => {
        switch (aspectRatio) {
            case "16:9":
                return "Landscape";
            case "9:16":
                return "Portrait";
            default:
                return "Unknown Aspect Ratio";
        }
    };
}
