export function useAspectRatioWidthHeight(
    aspectRatio?: string,
): [number, number] {
    if (!aspectRatio) {
        return [16, 9];
    }

    const [width, height] = aspectRatio.split(":").map(Number);
    return [width, height];
}
