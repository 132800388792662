import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useEventListener } from "@castia/sdk";
import { useCallback, useEffect } from "react";
import Channel from "model/Channel";
import LocalEvents from "events/LocalEvents";

/**
 * Fetch the channel list. Subscribe to the channel Change event and reload the data if it gets triggered.
 */
function useFetchAllChannels(aspectRatioId?: string): FetchData<Channel[]> {
    const fetch = useFetch<Channel[]>(
        `${environment.apiBaseUrl}/api/v1/channels`,
        new URLSearchParams({
            all: "1",
        }),
        {
            autoFetch: false, // AutoFetch is set to false because the initial fetch will always happen manually in the aspectRatio useEffect.
        }
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.CHANNELS_CHANGED, eventCallback);

    useEffect(() => {
        if (aspectRatioId) {
            fetch.refreshData(new URLSearchParams({ aspectRatio: aspectRatioId }));
        } else {
            fetch.refreshData();
        }
    }, [aspectRatioId]);

    return fetch;
}

export default useFetchAllChannels;
