import React from "react";
import { classNames } from "@castia/sdk";
import styles from "./ThumbnailPlaceholder.scss";

export function ThumbnailPlaceholder() {
    return (
        <div
            className={classNames(
                "d-flex",
                "justify-content-center",
                "align-items-center",
                styles.placeholder,
            )}
        >
            Thumbnail not available
        </div>
    );
}
