import * as React from "react";
import { ReactElement } from "react";

import { ButtonGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteChannelsPath } from "core/util/routes";
import { FolderListItem } from "model/FolderListItem";
import { ChannelMoveButton } from "channel/list/ChannelMoveButton";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { useLocationContext } from "hooks/useLocationContext";
import DeleteChannelButton from "channel/button/DeleteChannelButton";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { FolderRowItem } from "core/folder/FolderRowItem/FolderRowItem";
import { RowListItem } from "core/components/RowList/RowListItem";
import { AspectRatioIcon } from "core/field/AspectRatioIcon";
import { useAspectRatioLabel } from "hooks/useAspectRatioLabel";
import { RowListGroup } from "core/components/RowList/RowListGroup";
import { FolderNavigateUpRow } from "core/folder/FolderTableNavigateUp/FolderNavigateUpRow";

interface ChannelListProps {
    channels: FolderListItem[];
    folderId?: string;
}

function ChannelList(props: ChannelListProps): ReactElement {
    const locationContext = useLocationContext();
    const getAspectRatioLabel = useAspectRatioLabel();

    const channelList = props.channels?.map(
        (folderListItem: FolderListItem, index: number): ReactElement => {
            if (folderListItem.type === "FOLDER") {
                return (
                    <FolderRowItem
                        key={index}
                        folderListItem={folderListItem}
                        changeEvent={LocalEvents.CHANNELS_CHANGED}
                        folderType={FolderType.CHANNEL}
                    />
                );
            }
            return (
                <RowListItem key={index} dataCy="channels-list-item">
                    <Col md={7}>
                        <Link
                            className="nav nav-link"
                            to={`${absoluteChannelsPath}/${folderListItem.id}`}
                            key={index}
                        >
                            {folderListItem.name
                                ? folderListItem.name
                                : folderListItem.id}
                        </Link>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
                        <AspectRatioIcon
                            aspectRatio={folderListItem.aspectRatio.aspectRatio}
                            size={20}
                        />
                        &nbsp;
                        {getAspectRatioLabel(
                            folderListItem.aspectRatio.aspectRatio,
                        )}
                    </Col>
                    <Col md={2} className="text-end">
                        <HasLocationRole
                            locationId={locationContext}
                            role={ScopedRole.EDITOR}
                        >
                            <ButtonGroup>
                                <ChannelMoveButton
                                    channelId={folderListItem.id}
                                    currentFolderId={folderListItem.folder}
                                />
                                <OverflowMenu variant="secondary">
                                    <DeleteChannelButton
                                        channelId={folderListItem.id}
                                        variant="dropdown"
                                    />
                                </OverflowMenu>
                            </ButtonGroup>
                        </HasLocationRole>
                    </Col>
                </RowListItem>
            );
        },
    );

    return (
        <RowListGroup dataCy="channels-list">
            <FolderNavigateUpRow parentFolderId={props.folderId} />
            {channelList}
        </RowListGroup>
    );
}

export default ChannelList;
