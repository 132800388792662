import React, { ReactElement } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteSchedulePath } from "core/util/routes";
import { ScheduleDeleteButton } from "schedule/schedule/components/ScheduleDeleteButton";
import { FolderListItem } from "model/FolderListItem";
import { ScheduleMoveButton } from "schedule/schedule/components/ScheduleMoveButton";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { useLocationContext } from "hooks/useLocationContext";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { RowListGroup } from "core/components/RowList/RowListGroup";
import { FolderNavigateUpRow } from "core/folder/FolderTableNavigateUp/FolderNavigateUpRow";
import { FolderRowItem } from "core/folder/FolderRowItem/FolderRowItem";
import { RowListItem } from "core/components/RowList/RowListItem";
import { AspectRatioIcon } from "core/field/AspectRatioIcon";
import { useAspectRatioLabel } from "hooks/useAspectRatioLabel";

interface ScheduleListProps {
    schedules: FolderListItem[];
    folderId?: string;
}

export function ScheduleList(props: ScheduleListProps): ReactElement {
    const locationContext = useLocationContext();
    const getAspectRatioLabel = useAspectRatioLabel();

    return (
        <RowListGroup dataCy="schedules-list">
            <FolderNavigateUpRow parentFolderId={props.folderId} />
            {props.schedules.map((folderListItem, index) => {
                if (folderListItem.type === "FOLDER") {
                    return (
                        <FolderRowItem
                            key={index}
                            folderListItem={folderListItem}
                            changeEvent={LocalEvents.SCHEDULES_CHANGED}
                            folderType={FolderType.SCHEDULE}
                        />
                    );
                }

                return (
                    <RowListItem key={index} dataCy="schedules-list-row-item">
                        <Col md={7}>
                            <Link
                                className="nav nav-link"
                                to={`${absoluteSchedulePath}/${folderListItem.id}`}
                            >
                                {folderListItem.name}
                            </Link>
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                            <AspectRatioIcon
                                aspectRatio={
                                    folderListItem.aspectRatio.aspectRatio
                                }
                                size={20}
                            />
                            &nbsp;
                            {getAspectRatioLabel(
                                folderListItem.aspectRatio.aspectRatio,
                            )}
                        </Col>
                        <Col md={2} className="text-end">
                            <ButtonGroup>
                                <HasLocationRole
                                    locationId={locationContext}
                                    role={ScopedRole.EDITOR}
                                >
                                    <ScheduleMoveButton
                                        currentFolderId={props.folderId}
                                        scheduleId={folderListItem.id}
                                    />
                                    <OverflowMenu variant="secondary">
                                        <ScheduleDeleteButton
                                            schedule={folderListItem}
                                            inList={true}
                                            variant="dropdown"
                                        />
                                    </OverflowMenu>
                                </HasLocationRole>
                            </ButtonGroup>
                        </Col>
                    </RowListItem>
                );
            })}
        </RowListGroup>
    );
}
