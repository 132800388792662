import { MdCropPortrait, MdCropLandscape, MdAspectRatio } from "react-icons/md";
import React from "react";

interface AspectRatioIconProps {
    aspectRatio: string;
    size?: number;
}

export function AspectRatioIcon(props: AspectRatioIconProps) {
    switch (props.aspectRatio) {
        case "16:9":
            return <MdCropLandscape size={props.size || 80} />;
        case "9:16":
            return <MdCropPortrait size={props.size || 80} />;
        default:
            return <MdAspectRatio size={props.size || 80} />;
    }
}
