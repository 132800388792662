import React, { PropsWithChildren } from "react";
import { Row } from "react-bootstrap";
import styles from "./RowListItem.scss";
import { classNames } from "@castia/sdk";

interface RowListItemProps {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    dataCy?: string;
}

export function RowListItem(props: PropsWithChildren<RowListItemProps>) {
    return (
        <Row
            className={classNames(styles.rowListItem, props.className)}
            onClick={props.onClick}
            data-cy={props.dataCy}
        >
            {props.children}
        </Row>
    );
}
