import React, { ReactElement, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { classNames, ResponseErrorHandler } from "@castia/sdk";
import { FaCheck } from "react-icons/fa";
import styles from "./AspectRatioInput.scss";
import { AspectRatioIcon } from "core/field/AspectRatioIcon";
import { useController, useFormContext } from "react-hook-form";
import Error from "core/components/UI/Error/Error";
import { useAspectRatioLabel } from "hooks/useAspectRatioLabel";
import { useFetchLocationAspectRatios } from "hooks/api/aspect-ratio/useFetchLocationAspectRatios";
import { useLocationContext } from "hooks/useLocationContext";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface AspectRatioInputProps {
    defaultAspectRatio?: LocationAspectRatio;
}

export function AspectRatioInput(props: AspectRatioInputProps): ReactElement {
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name: "aspectRatio",
        control: control,
        rules: {
            required: "Please select an aspect ratio.",
        },
        defaultValue: props.defaultAspectRatio,
    });
    const getAspectRatioLabel = useAspectRatioLabel();
    const locationId = useLocationContext();
    const { response, isLoading, error, refreshData } =
        useFetchLocationAspectRatios(locationId);
    const [selectedAspectRatio, setSelectedAspectRatio] =
        React.useState<LocationAspectRatio>(null);

    useEffect(() => {
        field.onChange(selectedAspectRatio);
    }, [selectedAspectRatio]);

    useEffect(() => {
        if (response && response.length > 0 && selectedAspectRatio === null) {
            setSelectedAspectRatio(props.defaultAspectRatio || response[0]);
        }
    }, [response]);

    return (
        <>
            <Row className="mt-2">
                <label className="form-label mb-0">Orientation</label>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response?.map((locationAspectRatio, index) => {
                        return (
                            <Col
                                key={index}
                                lg={3}
                                className={
                                    selectedAspectRatio?.id ===
                                    locationAspectRatio.id
                                        ? styles.selected
                                        : ""
                                }
                            >
                                <Card className="my-2">
                                    <div
                                        className={styles.selectedOverlay}
                                        onClick={() =>
                                            setSelectedAspectRatio(
                                                locationAspectRatio,
                                            )
                                        }
                                        data-cy="aspect-ratio-selector-select"
                                    >
                                        <div
                                            className={classNames(
                                                styles.checkContainer,
                                                "rounded-circle",
                                            )}
                                        >
                                            <FaCheck />
                                        </div>
                                    </div>
                                    <Card.Body className="p-0">
                                        <div className="text-center">
                                            <AspectRatioIcon
                                                aspectRatio={
                                                    locationAspectRatio.aspectRatio
                                                }
                                            />
                                        </div>
                                        <div className="p-2 text-center text-truncate">
                                            {locationAspectRatio.displayName ||
                                                getAspectRatioLabel(
                                                    locationAspectRatio.aspectRatio,
                                                )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </ResponseErrorHandler>
            </Row>
            {fieldState.error && <Error>{fieldState.error.message}</Error>}
        </>
    );
}
