import { ReactElement } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Modal from "core/components/UI/Modal/Modal";
import { Form, FormGroup, Row } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import styles from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal.scss";
import TemplateSelectInput from "scene/ScenesOverviewPage/components/SceneCreator/TemplateSelectInput";
import Button from "core/components/UI/Button/Button";
import * as React from "react";
import { FormStepProps } from "core/components/Form/FormStepProps";
import { SceneCreateFormType } from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal";
import { AspectRatioInput } from "core/input/AspectRatioInput";

interface SceneCreateFormStep1FormData {
    title: string;
    template: string;
}

export function SceneCreateFormStep1(
    props: FormStepProps<SceneCreateFormType>
): ReactElement {
    const form = useForm<SceneCreateFormStep1FormData>({
        defaultValues: props.initialData,
    });
    const { errors } = form.formState;

    return (
        <>
            <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.onSubmit)}>
                <Modal.Body>
                    <FormGroup>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            {...form.register("title", {
                                required: "Title is a required field"
                            })}
                        />
                        {errors.title && (
                            <FormInputError>
                                {errors.title.message}
                            </FormInputError>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <AspectRatioInput />
                    </FormGroup>
                    <FormGroup>
                        <Form.Label className={styles.templateLabel}>
                            Template
                        </Form.Label>
                        {errors.template && (
                            <FormInputError>
                                {errors.template.message}
                            </FormInputError>
                        )}
                        <Row data-cy="template-select-row">
                            <TemplateSelectInput
                                name="template"
                            />
                        </Row>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={props.previous}>
                        Cancel
                    </Button>
                    <Button variant={"primary"} type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
            </FormProvider>
        </>
    );
}
