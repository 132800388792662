import { default as React, ReactElement } from "react";
import Modal from "core/components/UI/Modal/Modal";
import { AddSceneToChannelModalBody } from "channel/button/AddSceneToChannelModalBody";
import Channel from "model/Channel";

interface AddSceneToChannelModalProps {
    channel: Channel;
    onDone: () => void;
    show: boolean;
}

/**
 * Modal for adding scenes to a channel
 * @param props
 * @constructor
 */
function AddSceneToChannelModal(
    props: AddSceneToChannelModalProps
): ReactElement {
    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size={"xl"}
            title="Add scenes to the channel"
        >
            {props.show && (
                <AddSceneToChannelModalBody
                    channel={props.channel}
                    handleClose={handleClose}
                />
            )}
        </Modal>
    );
}

export default AddSceneToChannelModal;
