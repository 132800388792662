import { useSearchParams } from "react-router-dom";
import { useFetchFolder } from "hooks/api/folder/useFetchFolder";
import React, { MouseEvent, ReactElement } from "react";
import { Col } from "react-bootstrap";
import { FaLevelUpAlt } from "react-icons/fa";
import { RowListItem } from "core/components/RowList/RowListItem";
import styles from "./FolderNavigateUpRowItem.scss";

interface FolderNavigateUpTableRowProps {
    folderId: string;
}

export function FolderNavigateUpRowItem(props: FolderNavigateUpTableRowProps): ReactElement {
    const [_, setSearchParams] = useSearchParams();
    const folder = useFetchFolder(props.folderId);

    function onClick(event: MouseEvent): void {
        event.preventDefault();
        if (folder.response.parent) {
            setSearchParams({
                folderId: folder.response.parent.id,
            });
        } else {
            setSearchParams({});
        }
    }

    return (
        <RowListItem
            onClick={onClick}
            className={styles.listGroupItem}
        >
            <Col md={12}>
                <div className={styles.main}>
                    <FaLevelUpAlt />
                    <a className="nav nav-link" href="#">
                        ..
                    </a>
                </div>
            </Col>
        </RowListItem>
    );
}
