import * as React from "react";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Content } from "model/Content";
import styles from "scene/ScenesEditorPage/SceneEditorPage.scss";
import { ButtonGroup, Col, Container } from "react-bootstrap";
import useUpdateSceneContent from "hooks/api/scene/useUpdateSceneContent";
import DeleteSceneButton from "scene/ScenesEditorPage/components/DeleteSceneButton/DeleteSceneButton";
import SceneSettingsButton from "scene/ScenesEditorPage/components/SceneSettingsButton/SceneSettingsButton";
import SettingsSidebar from "scene/ScenesEditorPage/components/SettingsSidebar/SettingsSidebar";
import useFetchOneScene from "hooks/api/scene/useFetchOneScene";
import TemplateContainer from "core/components/TemplateContainer/TemplateContainer";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import Loader from "core/components/UI/Loader/Loader";
import { SceneLayoutButton } from "scene/ScenesEditorPage/components/SceneLayoutButton";
import { usePatchSceneSettings } from "hooks/api/scene/usePatchSceneSettings";
import { expandObject } from "@castia/sdk";
import { useLocationContext } from "hooks/useLocationContext";
import { deepMerge } from "core/util/deepMerge";
import { debounce } from "core/util/debounce";
import { SceneMovableToggle } from "scene/ScenesEditorPage/components/SceneMovableToggle";
import { ScenePreviewModeSelector } from "scene/ScenesEditorPage/components/ScenePreviewModeSelector";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { ScaledScene } from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene";
import { useOrientation } from "hooks/useOrientation";

const settingsSaveTimeout = 500;

/**
 * Page on which a scene can be edited.
 * @constructor
 */
function SceneEditorPage(): ReactElement {
    const { scene } = useParams();
    const { response, isLoading, error } = useFetchOneScene(scene);
    const patchSettings = usePatchSceneSettings(scene);
    const { sendRequest } = useUpdateSceneContent(scene);
    const [toggled, setToggled] = useState(true);
    const locationContext = useLocationContext();
    const [movingLocked, setMovingLocked] = useState(true);
    const [previewMode, setPreviewMode] = useState<string | undefined | null>();
    const orientation = useOrientation(response?.aspectRatio.aspectRatio);

    const updatedSettings = useRef(null);

    const debouncedUpdate = useCallback(
        debounce(() => {
            const updatedSettingsRequest = updatedSettings.current;
            updatedSettings.current = null;
            patchSettings.sendRequest({
                custom: updatedSettingsRequest,
            });
        }, settingsSaveTimeout),
        [],
    );

    const handleSettingsChange = useCallback(
        (value: string, key: string) => {
            if (updatedSettings.current) {
                updatedSettings.current = deepMerge(
                    updatedSettings.current,
                    expandObject({
                        [key]: value,
                    }),
                );
            } else {
                updatedSettings.current = expandObject({
                    [key]: value,
                });
            }

            debouncedUpdate();
        },
        [debouncedUpdate],
    );

    function saveContent(content: Content): void {
        sendRequest(content);
    }

    function saveSettingsItem(value: string, key: string) {
        handleSettingsChange(value, key);
    }

    function toggleSettingsSidebar(): void {
        setToggled(!toggled);
    }

    return (
        <>
            {!isLoading && response && (
                <SettingsSidebar
                    toggled={toggled}
                    scene={response}
                    toggleSidebar={toggleSettingsSidebar}
                    settings={response.settings}
                />
            )}
            <Container>
                <ResponseErrorHandler isLoading={!response} error={error}>
                    <div className={styles.header}>
                        <h4>{response?.title}</h4>
                        {isLoading && <Loader />}
                        <div className="d-flex">
                            <HasLocationRole
                                locationId={locationContext}
                                role={ScopedRole.READER}
                            >
                                <ScenePreviewModeSelector
                                    templateIdentifier={response?.template}
                                    templateLayoutId={response?.layout}
                                    settings={response?.settings}
                                    onModeChange={(previewModeId) => {
                                        setPreviewMode(previewModeId);
                                    }}
                                />
                            </HasLocationRole>
                            <HasLocationRole
                                locationId={locationContext}
                                role={ScopedRole.EDITOR}
                            >
                                <SceneMovableToggle
                                    movingLocked={movingLocked}
                                    setMovingLocked={setMovingLocked}
                                    templateIdentifier={response?.template}
                                    layoutIdentifier={response?.layout}
                                />
                            </HasLocationRole>
                            <ButtonGroup>
                                <HasLocationRole
                                    locationId={locationContext}
                                    role={ScopedRole.EDITOR}
                                >
                                    <SceneSettingsButton
                                        onClick={toggleSettingsSidebar}
                                    />
                                    <SceneLayoutButton scene={response} />
                                </HasLocationRole>
                                <HasLocationRole
                                    locationId={locationContext}
                                    role={ScopedRole.EDITOR}
                                >
                                    <DeleteSceneButton
                                        sceneId={scene}
                                        variant="button"
                                    />
                                </HasLocationRole>
                            </ButtonGroup>
                        </div>
                    </div>
                    {previewMode !== undefined && (
                        <Col
                            md={orientation === "portrait" ? 4 : 12}
                            className="m-auto"
                        >
                            <ScaledScene
                                locationAspectRatio={response?.aspectRatio}
                            >
                                <TemplateContainer
                                    key={response?.template + response?.layout}
                                    scene={response}
                                    editable={true}
                                    onContentChange={saveContent}
                                    onSettingsItemChange={saveSettingsItem}
                                    movable={!movingLocked}
                                    previewMode={previewMode}
                                />
                            </ScaledScene>
                        </Col>
                    )}
                </ResponseErrorHandler>
            </Container>
        </>
    );
}

export default SceneEditorPage;
