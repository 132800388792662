import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useEventListener } from "@castia/sdk";
import { useCallback, useEffect } from "react";
import LocalEvents from "events/LocalEvents";
import { Schedule } from "model/Schedule";

export function useFetchAllSchedules(
    aspectRatioId: string,
): FetchData<Schedule[]> {
    const fetch = useFetch<Schedule[]>(
        `${environment.apiBaseUrl}/api/v2/schedules`,
        new URLSearchParams({
            all: "1",
        }),
        {
            autoFetch: false,
        },
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCHEDULES_CHANGED, eventCallback);

    useEffect(() => {
        if (aspectRatioId) {
            fetch.refreshData(
                new URLSearchParams({ aspectRatio: aspectRatioId }),
            );
        } else {
            fetch.refreshData();
        }
    }, [aspectRatioId]);

    return fetch;
}
