import { default as React, ReactElement, useState } from "react";
import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";
import { TemplateLayout } from "@castia/sdk";
import LayoutPreview from "scene/ScenesOverviewPage/components/SceneCreator/LayoutPreview";
import { useFormContext } from "react-hook-form";

interface TemplateSelectInputProps {
    name: string;
    layouts: TemplateLayout[];
    template: EnrichedTemplateConfiguration;
    initialSelectedLayout?: string;
    aspectRatio: string;
}

/**
 * Input component to select a template from the list of template which are available to you.
 * @param props
 * @constructor
 */
function TemplateLayoutSelectInput(
    props: TemplateSelectInputProps
): ReactElement {
    const form = useFormContext();

    const initialSelectedIndex = props.layouts.findIndex(
        (layout) => layout.id === props.initialSelectedLayout
    );
    const [selectedLayout, setSelectedLayout] = useState({
        selectedLayoutIndex: initialSelectedIndex,
        layoutId: props.initialSelectedLayout || "",
    });

    function selectLayout(layoutIndex: number, layoutId: string): void {
        form.setValue(props.name, layoutId);
        setSelectedLayout({
            selectedLayoutIndex: layoutIndex,
            layoutId: layoutId,
        });
    }

    function renderTemplatePreview(
        layout: TemplateLayout,
        index: number
    ): ReactElement {
        const selected = selectedLayout.selectedLayoutIndex === index;
        return (
            <LayoutPreview
                key={index}
                index={index}
                selected={selected}
                layout={layout}
                template={props.template}
                selectedCallback={selectLayout}
                aspectRatio={props.aspectRatio}
            />
        );
    }

    return (
        <>
            {props.layouts.map(renderTemplatePreview)}
            <input
                type="hidden"
                {...form.register(props.name)}
            />
        </>
    );
}

export default TemplateLayoutSelectInput;
