import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./RowListGroup.scss";

interface RowListGroupProps {
    dataCy?: string;
}

export function RowListGroup(
    props: PropsWithChildren<RowListGroupProps>,
): ReactElement {
    return (
        <div className={styles.rowListGroup} data-cy={props.dataCy}>
            {props.children}
        </div>
    );
}
