import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useEventListener } from "@castia/sdk";
import { useCallback, useEffect } from "react";
import Scene from "model/Scene";
import LocalEvents from "events/LocalEvents";

/**
 * Fetch the channel list. Subscribe to the channel Change event and reload the data if it gets triggered.
 */
function useFetchAllScenes(aspectRatioId?: string): FetchData<Scene[]> {
    const fetch = useFetch<Scene[]>(
        `${environment.apiBaseUrl}/api/v1/scenes`,
        new URLSearchParams({
            all: "1",
        }),
        {
            autoFetch: false, // AutoFetch is set to false because the initial fetch will always happen manually in the aspectRatio useEffect.
        }
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEffect(() => {
        if (aspectRatioId) {
            fetch.refreshData(new URLSearchParams({ aspectRatio: aspectRatioId }));
        } else {
            fetch.refreshData();
        }
    }, [aspectRatioId]);

    useEventListener(LocalEvents.SCENES_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllScenes;
