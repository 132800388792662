import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { RegistrationCodeInput } from "device/create/input/RegistrationCodeInput";
import styles from "device/create/DeviceRegistrationForm.scss";
import LoaderCheckMark from "core/components/UI/LoaderCheckmark/LoaderCheckMark";
import { sleep } from "core/util/sleep";
import { RegistrationCompleteEventData } from "events/DeviceEventEmitter";
import LocalEvents from "events/LocalEvents";
import { classNames, useEventListener } from "@castia/sdk";
import useRegisterDevice from "hooks/api/device/useRegisterDevice";
import environment from "core/util/environment";
import { useTenantProfileContext } from "tenant-profile/TenantProfileContext";
import { AspectRatioInput } from "core/input/AspectRatioInput";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import Button from "core/components/UI/Button/Button";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface DeviceRegistrationFormProps {
    onComplete: (deviceId: string) => void;
}

interface DeviceRegistrationFormFields {
    registrationCode: string;
    aspectRatio: LocationAspectRatio;
}

/**
 * Device registration form.
 * @param props
 * @constructor
 */
function DeviceRegistrationForm(
    props: DeviceRegistrationFormProps,
): ReactElement {
    const context = useTenantProfileContext();
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const storedRegistrationCode = useRef("");
    const { error, sendRequest } = useRegisterDevice();
    const form = useForm<DeviceRegistrationFormFields>();
    const registrationCode = form.watch("registrationCode", "");

    useEventListener(
        LocalEvents.DEVICES_REGISTRATION_COMPLETE,
        registrationComplete,
    );

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    async function registrationComplete(
        completeEventData: RegistrationCompleteEventData,
    ): Promise<void> {
        if (
            completeEventData.registrationCode ===
            storedRegistrationCode.current
        ) {
            const remainingTime = 1000;
            await sleep(remainingTime);
            setLoadingComplete(true);
            await sleep(2000);
            props.onComplete(completeEventData.deviceId);
            return;
        }
    }

    function onSubmit(data: DeviceRegistrationFormFields): void {
        storedRegistrationCode.current = data.registrationCode;
        setLoading(true);
        sendRequest(
            {
                aspectRatio: data.aspectRatio.id,
            },
            [{ key: "registrationCode", value: data.registrationCode }],
        );
    }

    return (
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    {!loading && (
                        <>
                            <div className={styles.intro}>
                                Open the player app or navigate to{" "}
                                <a
                                    href={
                                        `https://${context.playerUrl}` ||
                                        environment.playerUrl
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {context.playerUrl || environment.playerUrl}
                                </a>{" "}
                                on the device you wish to register.
                            </div>
                            <RegistrationCodeInput disabled={loading} />
                            <AspectRatioInput
                                defaultAspectRatio={form.getValues(
                                    "aspectRatio",
                                )}
                            />
                            <div
                                className={classNames(
                                    styles.error,
                                    error ? "visible" : "invisible",
                                )}
                            >
                                Device not found. Did you enter the correct
                                code?
                            </div>
                            <Button
                                className="float-end"
                                variant="primary"
                                type="submit"
                                disabled={registrationCode.length < 8}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </Form>
            </FormProvider>
            {loading && <LoaderCheckMark complete={loadingComplete} />}
        </>
    );
}

export default DeviceRegistrationForm;
