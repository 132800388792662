import React, { PropsWithChildren } from "react";
import styles from "./AspectRatioBox.scss";
import { LocationAspectRatio } from "model/LocationAspectRatio";
import { useAspectRatioWidthHeight } from "hooks/useAspectRatioWidthHeight";

interface AspectRatioBoxProps {
    locationAspectRatio?: LocationAspectRatio;
}

export function AspectRatioBox(props: PropsWithChildren<AspectRatioBoxProps>) {
    const [w, h] = useAspectRatioWidthHeight(props.locationAspectRatio?.aspectRatio);

    return (
        <div
            className={styles.aspectRatioBox}
            style={{
                paddingTop: `${(Number(h) / Number(w)) * 100}%`,
            }}
        >
            <div className={styles.content}>{props.children}</div>
        </div>
    );
}
