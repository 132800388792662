import Modal from "core/components/UI/Modal/Modal";
import React, { useEffect } from "react";
import { DateSelectArg } from "@fullcalendar/common";
import { useCreateScheduleEventV2 } from "hooks/api/schedule-event/useCreateScheduleEventV2";
import {
    EventForm,
    EventFormType,
} from "schedule/schedule-event/components/EventForm";
import { ScheduleEvent } from "model/ScheduleEvent";
import { EventValidationResult } from "schedule/schedule-event/components/FullScheduleCalendar";
import { toast } from "react-toastify";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface CreateEventModalProps {
    show: boolean;
    selection: DateSelectArg;
    closeModal: () => void;
    scheduleId: string;
    addEventToCalendar: (event: ScheduleEvent) => void;
    validateEvent: (event: EventFormType) => EventValidationResult;
    locationAspectRatio: LocationAspectRatio;
}

export function EventCreateModal(props: CreateEventModalProps) {
    const req = useCreateScheduleEventV2(props.scheduleId);

    useEffect(() => {
        if (req.error) {
            toast.error(
                "Something went wrong while trying to create the event: " +
                    req.error.message,
            );
        }
    }, [req.error]);

    async function createEvent(data: EventFormType) {
        const startDateTime = data.startDate + "T" + data.startTime + "Z";
        const endDateTime = data.endDate + "T" + data.endTime + "Z";
        const duration =
            new Date(endDateTime).getTime() - new Date(startDateTime).getTime();

        let request = {
            channel: data.channel,
            startDateTime: startDateTime,
            duration: duration,
            repeating: data.repeating,
            repeatingEnd: data.repeatingEnd,
        };

        if (data.repeating) {
            request = Object.assign(request, {
                interval: data.interval,
                frequency: data.frequency,
                byWeekDay: data.byweekday,
                byMonthDay: data.bymonthday,
                byMonth: data.bymonth,
                bySetPos: data.bysetpos,
            });
        }

        const result = await req.sendRequest(request);

        if (result) {
            props.addEventToCalendar(result);
            props.closeModal();
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.closeModal}
            size={"lg"}
            title="Create Event"
            data-cy="event-create-modal"
        >
            <EventForm
                closeModal={props.closeModal}
                selection={props.selection}
                handleSubmit={createEvent}
                validateEvent={props.validateEvent}
                locationAspectRatio={props.locationAspectRatio}
            />
        </Modal>
    );
}
