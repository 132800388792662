import React, { ReactElement } from "react";
import { FolderNavigateUpRowItem } from "core/folder/FolderTableNavigateUp/FolderNavigateUpRowItem";

interface FolderTableNavigateUpProps {
    parentFolderId: string | null;
}

export function FolderNavigateUpRow(props: FolderTableNavigateUpProps): ReactElement {
    if (!props.parentFolderId) {
        return null;
    }

    return <FolderNavigateUpRowItem folderId={props.parentFolderId} />;
}
