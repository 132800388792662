import { default as React, ReactElement, useEffect, useState } from "react";
import TemplatePreview from "scene/ScenesOverviewPage/components/SceneCreator/TemplatePreview";
import usePlugins from "hooks/usePlugins";
import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";
import { useFormContext, useWatch } from "react-hook-form";

interface TemplateSelectInputProps {
    name: string;
}

/**
 * Input component to select a template from the list of template which are available to you.
 * @param props
 * @constructor
 */
function TemplateSelectInput(props: TemplateSelectInputProps): ReactElement {
    const { templates } = usePlugins();
    const form = useFormContext();
    const locationAspectRatio = useWatch({
        name: "aspectRatio"
    });
    const supportedTemplates = templates.filter((template) => {
        return template.supportedAspectRatios.some((aspectRatio) => {
            return aspectRatio.aspectRatio === locationAspectRatio?.aspectRatio;
        });
    });

    const [selectedTemplate, setSelectedTemplate] = useState({
        selectedTemplateIndex: -1,
        template: "",
    });

    useEffect(() => {
        setSelectedTemplate({
            selectedTemplateIndex: -1,
            template: "",
        });
    }, [locationAspectRatio?.aspectRatio]);

    function selectTemplate(templateIndex: number, template: string): void {
        form.setValue(props.name, template);
        setSelectedTemplate({
            selectedTemplateIndex: templateIndex,
            template: template,
        });
    }

    function renderTemplatePreview(
        template: EnrichedTemplateConfiguration,
        index: number
    ): ReactElement {
        const selected = selectedTemplate.selectedTemplateIndex === index;
        return (
            <TemplatePreview
                key={index}
                index={index}
                selected={selected}
                template={template}
                selectedCallback={selectTemplate}
                aspectRatio={locationAspectRatio?.aspectRatio}
            />
        );
    }

    return (
        <>
            {supportedTemplates.map(renderTemplatePreview)}
            <input
                type="hidden"
                {...form.register(props.name)}
            />
        </>
    );
}

export default TemplateSelectInput;
