import Scene from "model/Scene";
import PluginTemplateComponent from "core/components/Plugin/PluginTemplateComponent/PluginTemplateComponent";
import environment from "core/util/environment";
import React from "react";
import usePlugins from "hooks/usePlugins";
import { SceneFolderListItem } from "model/SceneFolderListItem";
import { deepMerge } from "core/util/deepMerge";
import { ScaledScene } from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene";

interface RenderedSceneFieldProps {
    scene: Scene | SceneFolderListItem;
}

export function RenderedSceneField(props: RenderedSceneFieldProps) {
    const { findTemplate } = usePlugins();
    const templateMetadata = findTemplate(props.scene.template);

    if (!templateMetadata) {
        return <div>Unknown template</div>;
    }

    const settings = {
        title: props.scene.settings.title,
        custom: deepMerge(
            {},
            templateMetadata.defaultSettings || {},
            props.scene.settings.custom
        ),
    };

    return <ScaledScene locationAspectRatio={props.scene.aspectRatio}>
        <PluginTemplateComponent
            template={
                templateMetadata.templateIdentifier
            }
            layoutId={props.scene.layout}
            resourceDirectory={
                templateMetadata.resourceDirectory
            }
            environment={environment}
            templateProps={{
                editable: false,
                content: props.scene.content,
                settings: settings,
                preview: true,
            }}
        />
    </ScaledScene>;
}
