import React, { ReactElement, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import useFetchAllChannels from "hooks/api/channel/useFetchAllChannels";
import Channel from "model/Channel";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";

interface DeviceChannelControlProps {
    contentId: string;
    aspectRatioId: string;
}

export function DeviceChannelControl(
    props: DeviceChannelControlProps
): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchAllChannels(props.aspectRatioId);
    const { register, setValue } = useFormContext();

    useEffect((): void => {
        setValue("contentId", props.contentId);
    }, [response]);

    const channelList = (response || []).map(
        (channel: Channel, index: number): ReactElement => {
            return (
                <option key={index} value={channel.id}>
                    {channel.title} ({channel.id})
                </option>
            );
        }
    );

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <Form.Label className="mt-2">Channel</Form.Label>
            <Form.Select {...register("contentId")}>
                <option value="">-</option>
                {channelList}
            </Form.Select>
        </ResponseErrorHandler>
    );
}
