import {
    EventForm,
    EventFormType,
} from "schedule/schedule-event/components/EventForm";
import { useFetchOneScheduleEvent } from "hooks/api/schedule-event/useFetchOneScheduleEvent";
import React from "react";
import { EventValidationResult } from "schedule/schedule-event/components/FullScheduleCalendar";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface EventUpdateFormContainerProps {
    scheduleId: string;
    scheduleEventId: string;
    closeModal: () => void;
    handleSubmit: (data: EventFormType) => void;
    validateEvent: (
        event: EventFormType,
        existingId: string,
    ) => EventValidationResult;
    locationAspectRatio: LocationAspectRatio;
}

export function EventUpdateFormContainer(props: EventUpdateFormContainerProps) {
    const existingEvent = useFetchOneScheduleEvent(
        props.scheduleId,
        props.scheduleEventId,
    );

    if (!existingEvent.response) {
        return <></>;
    }

    return (
        <EventForm
            handleSubmit={props.handleSubmit}
            closeModal={props.closeModal}
            existingEvent={existingEvent.response}
            validateEvent={props.validateEvent}
            locationAspectRatio={props.locationAspectRatio}
        />
    );
}
