import { default as React, ReactElement } from "react";
import { Form, FormGroup } from "react-bootstrap";
import Modal from "core/components/UI/Modal/Modal";
import Button from "core/components/UI/Button/Button";
import useCreateChannel from "hooks/api/channel/useCreateChannel";
import { FormProvider, useForm } from "react-hook-form";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import Error from "core/components/UI/Error/Error";
import { useQuery } from "hooks/useQuery";
import { FolderIdParams } from "core/folder/FolderIdParams";
import { AspectRatioInput } from "core/input/AspectRatioInput";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface ChannelCreatorModalProps {
    onDone: () => void;
    show: boolean;
}

interface FormValues {
    title: string;
    aspectRatio: LocationAspectRatio;
}

/**
 * Modal to create a new channel.
 * @param props
 * @constructor
 */
function ChannelCreateModal(props: ChannelCreatorModalProps): ReactElement {
    const { sendRequest, error, reset } = useCreateChannel();
    const form = useForm();
    const { folderId } = useQuery<FolderIdParams>();

    async function onSubmit(data: FormValues): Promise<void> {
        const result = await sendRequest({
            title: data.title,
            folder: folderId,
            aspectRatio: data.aspectRatio.id,
        });
        if (result) {
            form.reset();
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            title="Create new channel"
        >
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Modal.Body>
                        {error && (
                            <Error>
                                Something went wrong while saving the channel.
                            </Error>
                        )}
                        <FormGroup>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                {...form.register("title", {
                                    required: "Title is a required field",
                                })}
                            />
                            {form.formState.errors.title && (
                                <FormInputError>
                                    {form.formState.errors.title.message as string}
                                </FormInputError>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <AspectRatioInput />
                            {form.formState.errors.aspectRatio && (
                                <FormInputError>
                                    {form.formState.errors.aspectRatio.message as string}
                                </FormInputError>
                            )}
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
}

export default ChannelCreateModal;
