import React, { useState } from "react";
import styles from "device/create/input/RegistrationCodeInput.scss";
import ReactCodeInput from "react-code-input";
import { useFormContext } from "react-hook-form";

interface RegistrationCodeInputProps {
    disabled: boolean;
}

const LENGTH = 8;

export function RegistrationCodeInput(props: RegistrationCodeInputProps) {
    const [value, setValue] = useState<string>("");
    const form = useFormContext();

    function onChange(newValue: string) {
        setValue(newValue.toUpperCase());
        form.setValue("registrationCode", newValue.toUpperCase());
    }

    return (
        <>
            <label className="form-label mb-2">Registration code</label>
            <div className={styles.codeInput}>
                <ReactCodeInput
                    type="text"
                    fields={LENGTH}
                    onChange={onChange}
                    disabled={props.disabled}
                    value={value}
                />
            </div>
        </>
    );
}
