import React, { ReactElement, useEffect, useState } from "react";
import useFetchAllScenesPaginated from "hooks/api/scene/useFetchAllScenesPaginated";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import { SceneSelector } from "scene/input/SceneInput/SceneSelector";
import { SceneFolderListItem } from "model/SceneFolderListItem";
import { SceneFolderNavigateUp } from "scene/input/SceneInput/SceneFolderNavigateUp";
import { useFetchAllFolders } from "hooks/api/folder/useFetchAllFolders";
import { FolderType } from "model/FolderType";
import { useController, useFormContext } from "react-hook-form";
import Error from "core/components/UI/Error/Error";
import { LocationAspectRatio } from "model/LocationAspectRatio";

interface SceneInputProps {
    locationAspectRatio: LocationAspectRatio;
}

export function SceneInput(props: SceneInputProps): ReactElement {
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name: "scenes",
        control: control,
        rules: {
            required: "Please select at least one scene to add to the channel.",
        },
    });
    const { response, isLoading, error, refreshData } =
        useFetchAllScenesPaginated(null, 8, {
            aspectRatio: props.locationAspectRatio.id,
        });
    const [selectedScenes, setSelectedScenes] = useState<SceneFolderListItem[]>(
        []
    );
    const [selectedFolderId, setSelectedFolderId] = useState<string>(null);
    const folderTree = useFetchAllFolders(FolderType.SCENE);

    function isSelected(scene: SceneFolderListItem): boolean {
        const foundImage = selectedScenes.find(
            (selectedScene): boolean => selectedScene.id === scene.id
        );

        return !!foundImage;
    }

    function toggleSelect(scene: SceneFolderListItem): void {
        if (isSelected(scene)) {
            setSelectedScenes(
                selectedScenes.filter(
                    (selectedScene): boolean => selectedScene.id !== scene.id
                )
            );
        } else {
            setSelectedScenes((oldSelectedScenes): SceneFolderListItem[] => [
                ...oldSelectedScenes,
                scene,
            ]);
        }
    }

    useEffect(() => {
        if (selectedFolderId) {
            refreshData(new URLSearchParams({ folderId: selectedFolderId }));
        } else {
            refreshData(new URLSearchParams({ folderId: "" }));
        }
    }, [selectedFolderId]);

    useEffect(() => {
        field.onChange(selectedScenes);
    }, [selectedScenes]);

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <ResponseErrorHandler
                isLoading={folderTree.isLoading}
                error={folderTree.error}
                retryAction={() => folderTree.refreshData()}
            >
                {selectedFolderId && (
                    <SceneFolderNavigateUp
                        currentFolderId={selectedFolderId}
                        onFolderNavigateUp={(folderId) =>
                            setSelectedFolderId(folderId)
                        }
                    />
                )}
                {response && folderTree.response && (
                    <>
                        <SceneSelector
                            scenes={response.items}
                            selectedScenes={selectedScenes}
                            isSelected={isSelected}
                            toggleSelect={toggleSelect}
                            onFolderClick={(folderId) =>
                                setSelectedFolderId(folderId)
                            }
                            folderTree={folderTree.response}
                            locationAspectRatio={props.locationAspectRatio}
                        />
                        {fieldState.error && (
                            <Error>{fieldState.error.message}</Error>
                        )}
                        <div className="d-flex justify-content-end">
                            <Paging
                                currentPage={response.meta.currentPage}
                                onClick={(page): void => {
                                    refreshData(
                                        new URLSearchParams({
                                            page: String(page),
                                            limit: String(8),
                                        })
                                    );
                                }}
                                totalPages={response.meta.totalPages}
                                showMax={10}
                            />
                        </div>
                    </>
                )}
            </ResponseErrorHandler>
        </ResponseErrorHandler>
    );
}
